<template>
    <div>
        <Search @search="search" />
        <main>
            <div class="tabs">
                <span @click="changeFun(index)" v-for="(item,index) in tags" :class="index == current?'active':''" :key="index">{{item.name}}</span>
            </div>
        </main>
        <div class="main-box">
            <div v-if="current == 0& needList.length>0">
                <!-- 分类内容 -->
                <div class="classification-content">
                    <div class="litle-need">
                        <div class="litle-cell" v-for="item in needList" :key="item.id" @click="goDemand(item.id)">
                            <div class="litle-title">{{item.name}}</div>
                            <div class="tag-box">
                                <div class="tag" v-text="item.sendUserType == 1?'个人用户':'企业用户'"></div>
                                <div class="tag">{{industry[item.sendIndustry]}}</div>
                                <div class="tag">{{mapAll[item.sendProvince]}}{{mapAll[item.sendCity]}}</div>
                            </div>
                            <div class="litle-money">预算 ￥ {{item.sendMoneyBudget}}</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="pagination">
                    <el-pagination background layout="prev, pager, next" :page-size="needPage.pageSize" :total="needPage.totalRows">
                    </el-pagination>
                    <div class="total">共{{needPage.totalRows}}件/{{needPage.totalPage}}页</div>
                    <div class="jump">
                        跳转到
                        <input type="number" v-model="needNum">
                        页
                    </div>
                    <el-button style="background:#2EA7E0;color:#fff;width:108px;height:50px;font-size:20px" @click="goPage">前往</el-button>
                </div> -->
            </div>
            <div class="none" v-else-if="current == 0 & needList.length==0">
                <img src="../../assets/images/home/not.png" alt="">
                搜不到关键字的结果，请换个关键字或减少关键字。
            </div>
            <div v-if="current == 1 & goodShopDefList.length>0">

                <!-- 分类内容 -->
                <div class="classification-content">
                    <div class="nice-shop">
                        <div class="shop" v-for="item in goodShopDefList" :key="item.id" @click="goShop(item.id)">
                            <div class="shop-info">
                                <div>{{item.name}}</div>
                                <div class="shop-img">
                                    <img :src="$util.host +item.headPic" alt="">
                                    <div v-if="item.type == 2">企</div>
                                </div>
                                <div class="classType"><span v-for="val in item.shopClassify[0].littleItems" :key="val.id">{{val.name}}|</span></div>
                                <div class="shop-tags">
                                    <div v-show="item.shopClassify.length>1">多类</div>
                                    <div v-show="item.shopClassify.length==1">{{item.shopClassify[0].name}}</div>
                                </div>
                            </div>
                            <div class="shop-num">
                                <div>服务雇主{{ item.workForUserSize }}家</div>
                                月成交量{{ item.mouthOverSize }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="pagination">
                    <el-pagination background layout="prev, pager, next" :page-size="shopPage.pageSize" :total="shopPage.totalRows">
                    </el-pagination>
                    <div class="total">共{{shopPage.totalRows}}件/{{shopPage.totalPage}}页</div>
                    <div class="jump">
                        跳转到
                        <input type="number" v-model="shopNum">
                        页
                    </div>
                    <el-button style="background:#2EA7E0;color:#fff;width:108px;height:50px;font-size:20px" @click="goShopPage">前往</el-button>
                </div> -->
            </div>
            <div class="none" v-else-if="current == 1 & goodShopDefList.length==0">
                <img src="../../assets/images/home/not.png" alt="">
                搜不到关键字的结果，请换个关键字或减少关键字。
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Search from "../../components/search.vue";
import Footer from "../../components/footer.vue";
export default {
    components: { Search, Footer },
    data() {
        return {
            current: 0,
            tags: [
                { id: 0, name: '需求' },
                { id: 1, name: '店铺' },
            ],
            clssList: [],//分类数组
            needList: [],//需求数组
            provinceList: [],//省
            provinceIndex: 0,
            cityIndex: 0,
            show: false,
            industry: {
                'A': '农、林、牧、渔业',
                'B': '采矿业,',
                'C': '制造业',
                'D': '电力、热力、燃气及水生产和供应业',
                'E': '建筑业',
                'F': '批发和零售业',
                'G': '交通运输、仓储和邮政业',
                'H': '住宿和餐饮业',
                'I': '信息传输、软件和信息技术服务业',
                'J': '金融业',
                'K': '房地产业',
                'L': '租赁和商务服务业',
                'M': '科学研究和技术服务业',
                'N': '水利、环境和公共设施管理业',
                'O': '居民服务、修理和其他服务业',
                'P': '教育',
                'Q': '卫生和社会工作',
                'R': '文化、体育和娱乐业',
                'S': '公共管理、社会保障和社会组织',
                'T': '国际组织',
            }, //行业.
            mapAll: {}, //获取所有地址列表  mapAll[key(地址的code)]展示对应的省市
            goodShopDefList: [], //获取店铺
            form: {

            }
        };
    },
    mounted() {
        this.getAllMap()
        // 如果存在值就赋值
        if(this.$route.query.keyword){
            this.form = this.$route.query.keyword
            this.current = this.form.classifyId
            this.search(this.form)
        }
    },
    methods: {
        changeFun(index){
            this.current = index
            this.search()
        },
        // 获取所有地址
        getAllMap() {
            this.$util.post('/address/allMap').then(res => {
                this.mapAll = res.data.all
            })
        },
        // 搜索获取所有信息
        search(item) {
            if(item){
                this.form = item
            }
            this.form.classifyId = this.current
            if (this.current == 1) {
                // 店铺搜索
                this.$util.post('/shop/search', this.form).then(res => {
                    console.log(res);
                    this.goodShopDefList = res.data
                    console.log(this.goodShopDefList);

                })
            } else {
                // 需求搜索
                this.$util.post('/demand/search', this.form).then(res => {
                    this.needList = res.data
                    console.log(this.needList);
                })
            }

        },
        // 分页跳转   当前搜索部分是没有分类  暂时去掉
        goShopPage() {
            this.shopSelect.pageNo = this.shopNum
        },
        // 跳转查看需求
        goDemand(id) {
            this.$router.push({ path: '/demand', query: { id } })
        },
        goShop(id) {
            this.$router.push({ path: '/shop', query: { id } })
        }
    },
};
</script>

<style lang="scss" scoped>
.classType{
    -webkit-box-orient: vertical;
-webkit-line-clamp: 1;
display: -webkit-box;
text-overflow: ellipsis;
overflow: hidden;

}
main {
    width: 1000px;
    margin: 0 auto 30px;
}
.tabs {
    font-size: 16px;
    color: #666666;
    font-weight: bold;
    span {
        margin-right: 20px;
        cursor: pointer;
    }
    .active {
        color: #e40012;
    }
}
.none {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 130px 0 0;
    font-size: 16px;
    color: #ff6600;
    font-weight: bold;
    img {
        width: 38px;
        height: 32px;
        margin-right: 53px;
    }
}
.main-box {
    background: #f8f8f8;
    padding: 30px 0 60px;
}
.need {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .need-cell {
        width: 520px;
        height: 174px;
        padding: 30px;
        border: 1px solid #e3e3e3;
        margin-bottom: 20px;
        cursor: pointer;
        .title {
            font-size: 24px;
            color: #222;
            margin-bottom: 20px;
        }
        .tag-box {
            display: flex;
            align-items: center;
            .tag {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 4px 8px;
                max-width: 60px;
                color: #222;
                font-size: 14px;
                background: #e3e3e3;
                margin-right: 10px;
                border-radius: 4px;
            }
        }
        .need-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #e40012;
            font-size: 24px;
            font-weight: bold;
            margin-top: 30px;
            .need-logo {
                display: flex;
                align-items: center;
                color: #222;
                font-size: 24px;
                img {
                    width: 67px;
                    height: 67px;
                    margin-right: 10px;
                }
            }
        }
    }
}
.litle-need {
    display: flex;
    justify-content:left;
    flex-wrap: wrap;
    .litle-cell:hover {
        border-color: #e40012;
        background: linear-gradient(#fefafa, #fef2f2);
    }
    .litle-cell {
        cursor: pointer;
        margin-right: 12px;
        background: #fffafa;
        width: 245px;
        padding: 30px 20px 30px;

        margin-bottom: 20px;
        border: 1px solid #fde1e2;
        .litle-title {
            font-size: 24px;
            color: #222;
        }
        .tag-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0 24px;
            .tag {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 4px 8px;
                max-width: 60px;
                color: #222;
                font-size: 14px;
                background: #e3e3e3;
                margin-right: 10px;
                border-radius: 4px;
            }
        }
        .litle-money {
            font-size: 18px;
            color: #e40012;
        }
    }
}
// 分类
.classification {
    width: 1200px;
    background: #fff;
    padding: 20px;
    margin: 20px auto 20px;
    display: flex;
    align-items: center;
}
.slece {
    cursor: pointer;
    margin-right: 80px;
    font-size: 16px;
    font-weight: bold;
}
.classification-content {
    width: 1200px;
    margin: 0 auto 65px;
    padding: 20px 20px 0;
    background: #fff;
}
.pagination {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
/deep/.el-pager li {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px !important;
    border: 1px solid #2ea7e0;
    margin: 0 3px;
    color: #2ea7e0 !important;
}
/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px !important;
    // border: 1px solid #2ea7e0;
    background: transparent;
    margin: 0 3px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #fff !important;
}
/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
    font-size: 24px;
    color: #2ea7e0;
}
/deep/.el-pager li.active + li {
    border: 1px solid #2ea7e0;
}
.total {
    color: #2ea7e0;
    font-weight: bold;
    font-size: 16px;
}
.jump {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 0 20px 0 40px;
    color: #2ea7e0;
    input {
        width: 40px;
        height: 40px;
        border: 1px solid #0f6c97;
        font-size: 14px;
        color: #2ea7e0;
        outline: none;
        margin: 0 10px;
        border-radius: 4px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.nice-shop {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}
.shop {
    width: 280px;
    border: 1px solid #e3e3e3;
    margin-bottom: 15px;
    margin-right:3px;
    cursor: pointer;
}
.shop-info {
    padding: 15px;
    color: #222;
    font-size: 16px;
}
.shop-img {
    position: relative;
    height: 178px;
    // background: red;
    margin: 10px 0 20px;
    img {
        width: 100%;
        height: 100%;
    }
    div {
        width: 42px;
        color: #fff;
        height: 31px;
        line-height: 31px;
        font-size: 14px;
        text-align: center;
        background: #707070;
        position: absolute;
        top: 0;
        left: 0;
    }
}
.shop-tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 10px;
    div {
        padding: 7px 19px;
        background: #fde5e6;
        color: #e40012;
        margin-right: 10px;
        font-size: 12px;
        border-radius: 4px;
    }
}
.shop-num {
    padding: 20px 15px;
    background: #f8f8f8;
    color: #999999;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.areas {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 14px;
    color: #606266;
    i {
        margin-left: 4px;
    }
    .down {
        position: absolute;
        top: 45px;
        left: -20px;
        z-index: 1000;
        // right: 0;
        // padding: 10px;
        // box-shadow: 1px 1px 10px #f1f1f1;
        max-height: 500px;
        overflow-y: auto;
        .down-item {
            background: #fff;
            width: 150px;
            padding: 10px;
            font-size: 14px;
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .active {
            background: #2ea7e0;
            color: #fff;
            width: 150px;
            padding: 10px;
            font-size: 14px;
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .downt {
        position: absolute;
        z-index: 1000;
        top: 45px;
        left: 150px;
        // right: 0;
        // padding: 10px;
        // box-shadow: 1px 1px 10px #f1f1f1;
        max-height: 500px;
        overflow-y: auto;
        .down-item {
            background: #fff;
            width: 150px;
            padding: 10px;
            font-size: 14px;
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .active {
            background: #2ea7e0;
            color: #fff;
            width: 150px;
            padding: 10px;
            font-size: 14px;
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .down-item:hover {
            background: #2ea7e0;
            color: #fff;
        }
    }
}
.down::-webkit-scrollbar {
    display: none;
}
.downt::-webkit-scrollbar {
    display: none;
}
</style>